const active = process.env.REACT_APP_ENABLE_APM === 'true' || false;
const environment = process.env.REACT_APP_ENVIRONMENT || 'unknown';

if (active) {
  const config = {
    active,
    instrument: active,
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'web-ui',

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: 'https://852650fdc5584c8898a28b8f71971131.apm.us-east-1.aws.cloud.es.io:443',

    // Set the service version (required for source map feature)
    serviceVersion: '0.0.1',

    // Set the service environment
    environment,
  };

  // eslint-disable-next-line unicorn/prefer-module
  const apm = require('@elastic/apm-rum').init(config);
}

