import SimpleSchema from 'simpl-schema';

SimpleSchema.extendOptions([
  'uiType',
  'placeholder',
  'readOnly',
  'helperText',
  'autoComplete',
  'options',
  'labelKey',
]);
