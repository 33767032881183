import { withoutKeys } from '@avicado/type';
import { isNullish } from '@avicado/util';

import React, { useState } from 'react';
import { actionDispatch } from './action-dispatch';
import { makeConnectedContainer } from './make-connected-container';

import { Log } from '@avicado/log';

const log = Log.create('InitializationContainer');

const makeInitializationContainer = container => {
  return class InitializationContainer extends React.Component {
    initialize() {
      if (isNullish(this.props)) {
        return;
      }
      const { initialized = false } = this.state || {};
      log.assert(!initialized, 'Multiply initialized container!')

      const { initializerNames } = this.props;
      if (isNullish(initializerNames)) {
        return;
      }

      // run init
      for (const initializerName of initializerNames) {
        this.props[initializerName]({});
      }
      this.setState({ initialized: true });
    }

    deinitialize() {
      if (isNullish(this.props)) {
        return;
      }

      const { deinitializerNames } = this.props;
      if (isNullish(deinitializerNames)) {
        return;
      }

      const { deinitialized = false } = this.state || {};
      log.assert(!deinitialized, 'Multiply deinitialized container!')

      // run init
      for (const deinitializerName of deinitializerNames) {
        this.props[deinitializerName]({});
      }
      this.setState({ deinitialized: true });
    }

    componentDidMount() {
      this.initialize();
    }

    componentWillUnmount() {
      this.deinitialize();
    }


    render() {
      const {
        initializerNames = [],
        initialize,
        deinitializerNames = [],
        deinitialize,
        ...props
      } = this.props;

      const propsWithoutInitializers = withoutKeys(props, [
        ...initializerNames, 'initializerNames',
        ...deinitializerNames, 'deinitializerNames',
      ]);

      return React.createElement(container, propsWithoutInitializers);
    }
  }
}

const buildInitializer = (prefix,init) => {
  let object;
  if (Array.isArray(init)) {
    // eslint-disable-next-line unicorn/no-array-reduce
    object = init.reduce((all, fn, i) => ({ ...all, [`${prefix}${i}`]: fn }), {});
  } else if (init.apply && init.call) {
    object = { [`${prefix}0`]: init };
  }  else { // it's an object, remap the prop names to <prefix>prop
    // eslint-disable-next-line unicorn/no-array-reduce
    object = Object.keys(init).reduce(
      (object_, oldName) => ({ ...object_, [`${prefix}${oldName}`]: init[oldName] }),
      {}
    );
  }
  return object;
};

export const withInitializer = (initialize, deinitialize, container) => {
  const initializers = buildInitializer('init-', initialize || []);
  const initializerNames = Object.keys(initializers);
  const deinitializers = buildInitializer('deit-', deinitialize || []);
  const deinitializerNames = Object.keys(deinitializers);

  const { name } = container || { name: 'Undefined' };
  return makeConnectedContainer(
    `Initialize${name}`,
    {
      state: {
        initializerNames: () => initializerNames,
        deinitializerNames: () => deinitializerNames
      },
      dispatch: {
        ...initializers,
        ...deinitializers
      }
    },
    makeInitializationContainer(container)
  );
};


export const combineInitializers = (...initializers) => {
  const dispatchFuncs = initializers.map(i => actionDispatch(i));

  // eslint-disable-next-line unicorn/no-array-for-each
  return (dispatch) => dispatchFuncs.forEach(fn => dispatch(fn()));
}
